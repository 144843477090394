import { FC } from 'react'
import { generateCSSVariables } from '../../helpers'
import { ThemeProps } from '../themes.types'
import styles from '../../Theme.module.scss'

export const defaultLightTheme: ThemeConfig = {
  body: {
    backgroundColor: '#f4f4f4',
    borderRadius: '0.75rem',
    focusBorderColor: '#0F62FE',
    borderColor1: '#e0e0e0',
    borderColor2: '#c6c6c6',

    shadowColor1: 'rgba(244, 244, 244, 0.1)',
    shadowColor2: 'rgba(244, 244, 244, 0.25)',
    shadowDropColor1: 'rgba(22, 22, 22, 0.05)',
    shadowPrimary:
      '0px -1px 1px 0px var(--body-shadowColor1), 0px 1px 1px 0px var(--body-shadowColor2), 0px 2px 6px 0px var(--body-shadowDropColor1)',
  },
  input: {
    textColor: '#161616',
    backgroundColor: '#F4F4F4',
    activeBorderColor: '#0F62FE',
    errorTextColor: '#DA1E28',
    errorBorderColor: '#DA1E28',
    placeholderTextColor: '#A8A8A8',
    borderRadius: '0.625rem',
  },
  paymentMethodCard: {
    backgroundColor: '#ffffff',
    titleColor: '#161616',
    captionColor: '#525252',
    cardMethodColor: '#33B1FF',
    p2pMethodColor: '#08BDBA',
    mobileCommerceMethodColor: '#EE5396',
    sbpMethodColor: '#A56EFF',
    upiMethodColor: '#33B1FF',
  },
  button: {
    primaryBackgroundColor: '#0F62FE',
    primaryBackgroundColorHover: '#0050E6',
    primaryBackgroundColorActive: '#002D9C',
    primaryTextColor: '#FFFFFF',

    secondaryBackgroundColor: 'transparent',
    secondaryBackgroundColorHover: '#F4F4F4',
    secondaryBackgroundColorActive: '#C6C6C6',
    secondaryTextColor: '#525252',
    secondaryBorderColor: '#C6C6C6',

    withShadowBackgroundColor: '#ffffff',
    withShadowBackgroundColorHover: '#F4F4F4',
    withShadowBackgroundColorActive: '#C6C6C6',
    withShadowTextColor: '#525252',
    withShadowBorderColor: '#C6C6C6',

    tertiaryBackgroundColor: 'transparent',
    tertiaryBackgroundColorHover: 'rgba(15, 98, 254, 0.05)',
    tertiaryBackgroundColorActive: 'rgba(15, 98, 254, 0.2)',
    tertiaryTextColor: '#0F62FE',
    tertiaryBorderColor: '#0F62FE',

    ghostBackgroundColor: '##F4F4F4',
    ghostBackgroundColorHover: 'rgba(15, 98, 254, 0.05)',
    ghostBackgroundColorActive: 'rgba(15, 98, 254, 0.2)',
    ghostTextColor: '#0F62FE',

    dangerBackgroundColor: '#DA1E28',
    dangerBackgroundColorHover: '#B81922',
    dangerBackgroundColorActive: '#750E13',
    dangerTextColor: '#FFFFFF',

    disabledBackgroundColor: '#C6C6C6',
    disabledBorderColor: '#C6C6C6',
    disabledTextColor: 'rgba(22, 22, 22, 0.25)',

    borderRadius: '0.75rem',
  },
  text: {
    primary: '#161616',
    secondary: '#525252',
    basicInformation: '#0f62fe',
    helper: '#6F6F6F',
    disabled: 'rgba(22, 22, 22, 0.25)',
  },
  link: {
    primary: '#0f62fe',
    primaryHover: '#054ada',
    secondary: '#525252',
    secondaryHover: '#161616',
    visited: '#8a3ffc',
  },
  icon: {
    primary: '#161616',
    secondary: '#525252',
    active: '#e0e0e0',
    danger: '#da1e28',
    buttonColor: '#ffffff',
    radioButton: '#0f62fe',
    buttonColorDisabled: '#8d8d8d',
    disabled: 'rgba(22, 22, 22, 0.25)',
  },
  savedCard: {
    borderColor: '#E0E0E0',
    dangerBorderColor: '#DA1E28',
    backgroundColor: '#FFF',
    textColor: '#161616',
  },
  errorDisplay: {
    completedIconFill: '#24a148',
    contactIconFill: '#F1C21B',
  },
  themeSelect: {
    backgroundColor: '#F4F4F4',
    activeButtonColor: '#0f62fe',
    borderRadius: '0.75rem',
  },
  languageSelect: {
    itemBackgroundColor: '#f4f4f4',
    itemHoverBackgroundColor: '#C6C6C6',
    itemTextColor: '#0F62FE',
  },
  tooltip: {
    backgroundColor: '#161616',
    textColor: '#f4f4f4',
    borderRadius: '0.25rem',
  },
}

export const LightTheme: FC<ThemeProps> = ({ variables }) => {
  return (
    <style>
      {`.${styles.lightTheme} {\n`}
      {generateCSSVariables({
        ...defaultLightTheme,
        ...variables,
      })}
      {'}'}
    </style>
  )
}

import { FC } from 'react'
import axios, { AxiosError } from 'axios'
// local libs
import { Link, Text } from 'src/shared/ui'
import { ChevronLeft } from 'src/shared/assets/icons'
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { store } from 'src/app/store'
import { api, userSession } from 'src/app/config/api'
// styles
import styles from './Terms.module.scss'
// types
import { TextKind, TextSizes } from 'src/shared/ui/Text/types'
import { LinkVariants } from 'src/shared/ui/Link/types'

export const Terms: FC = () => {
  const { translate } = useTranslate()

  const redirectUrl = store.paymentData.value?.urls.return

  const makeRedirect = () => {
    if (redirectUrl) window.location.href = redirectUrl
  }

  const handleBackToShop = () => {
    if (!userSession) {
      makeRedirect()
    } else {
      axios
        .get(api.closeSession)
        .then(makeRedirect)
        .catch((err: AxiosError) => {
          console.error("Can't close session:", err)
        })
    }
  }

  return (
    <div className={styles.termsContainer}>
      {!redirectUrl ? null : (
        <Text as="div" className={styles.cancelBtn}>
          <Link onClick={handleBackToShop}>
            <ChevronLeft />
            {translate('Layout.cancelBtn')}
          </Link>
        </Text>
      )}

      <div className={styles.termsText}>
        <Text as="div" kind={TextKind.label} size={TextSizes.large}>
          {translate('Layout.terms1')}
          &nbsp;
          <Link variant={LinkVariants.secondary}>
            {translate('Layout.terms2')}
          </Link>
        </Text>
      </div>
    </div>
  )
}

import { FC } from 'react'

export enum MethodNameEnum {
  Bankcard = 'Bankcard',
  P2P = 'P2P',
  P2PWithoutBank = 'P2P no bank selection',
  SBP = 'SBP',
  MobileCommerce = 'Mobile Commerce',
  SberPay = 'SberPay',
  UPI = 'UPI',
}

export type PaymentMethodProps = {
  disabled?: boolean
}

export type MethodComponents = Record<MethodNameEnum, FC<PaymentMethodProps>>

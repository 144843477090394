import { useEffect, useState } from 'react'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useNavigate } from 'react-router-dom'
// local libs
import { extendAxiosHeaders } from 'src/shared/utils'
import { initI18n } from '../config/i18next'
import { storeActions } from '../store'
import { PagesEnum } from '../routes'

export const useAppInit = () => {
  const navigate = useNavigate()
  const [isI18nInitiated, setIsI18nInitiated] = useState(false)
  const [isTerminalDataLoaded, setIsTerminalDataLoaded] = useState(false)

  const makeFingerprint = async () => {
    const fp = await FingerprintJS.load()
    const { visitorId: fingerprint } = await fp.get()
    extendAxiosHeaders({ Fingerprint: fingerprint })
  }

  const initInternationalization = async () => {
    await initI18n()
    setIsI18nInitiated(true)
  }

  const requestTerminalData = async () => {
    storeActions
      .collectTerminalData()
      .catch(() => navigate(PagesEnum.contactStore))
      .finally(() => setIsTerminalDataLoaded(true))
  }

  useEffect(() => {
    const initApp = async () => {
      await makeFingerprint()
      await requestTerminalData()
      initInternationalization()
    }
    initApp()
  }, [])

  const isAppInitiated = isI18nInitiated && isTerminalDataLoaded

  return { isAppInitiated }
}

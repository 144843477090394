// local libs
import { store } from 'src/app/store'
// types
import { ThemeEnum } from 'src/shared/types/enums'

export function getInitTheme(): ThemeEnum {
  const storedTheme = window?.localStorage.getItem('themeValue') as ThemeEnum
  const minifiedViewEnabled =
    store.paymentData.value?.terminalConfig.theme?.settings?.minifiedViewEnabled

  if (minifiedViewEnabled) return ThemeEnum.light

  if (storedTheme) {
    return ThemeEnum[storedTheme] || ThemeEnum.light
  }

  const systemTheme = window?.matchMedia('(prefers-color-scheme: dark)')
    ? ThemeEnum.dark
    : ThemeEnum.light
  window?.localStorage.setItem('themeValue', systemTheme)

  return systemTheme
}

import * as yup from 'yup'
import {
  validationInvalidFormatKey,
  validationRequiredKey,
} from 'src/shared/constants'

export const nameValidationSchema = yup
  .string()
  .matches(/^[a-zA-Z ',.()-]*$/, validationInvalidFormatKey)

export const nameRequiredValidationSchema = nameValidationSchema.required(
  validationRequiredKey,
)

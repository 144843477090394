import { FC } from 'react'
import cn from 'classnames'
// local libs
import { CheckMark, ErrorXIcon, WarningIcon } from 'src/shared/assets/icons'
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { Link, Text } from 'src/shared/ui'
import { store } from 'src/app/store'
// types
import { ErrorDisplayProps } from './ErrorDisplay.types'
import { TextKind } from 'src/shared/ui/Text'
// styles
import styles from './ErrorDisplay.module.scss'

export const ErrorDisplay: FC<ErrorDisplayProps> = ({ variant }) => {
  const { translate } = useTranslate()
  const redirectUrl = store.paymentData.value?.urls.return

  const currentStateValues = {
    completed: {
      icon: <CheckMark />,
      title: 'Status Card.statusCompletedTitle',
      subTitle: 'Status Card.statusCompletedSubtitle',
      linkText: 'Status Card.statusCompletedLinkText',
      containerClassName: styles.completedContainer,
    },
    error: {
      icon: <ErrorXIcon />,
      title: 'Status Card.statusErrorTitle',
      subTitle: 'Status Card.statusErrorSubtitle',
    },
    contact: {
      icon: <WarningIcon />,
      title: 'Status Card.statusContactTitle',
      subTitle: 'Status Card.statusContactSubtitlePart1',
      additionalSubtitle: 'Status Card.statusContactSubtitlePart2',
      containerClassName: styles.contactContainer,
    },
  }[variant]

  return (
    <div
      className={cn(
        styles.container,
        currentStateValues?.containerClassName ?? '',
      )}
    >
      <div className={styles.icon}>{currentStateValues?.icon}</div>

      <Text kind={TextKind.headline} className={styles.title}>
        {translate(currentStateValues?.title)}
      </Text>

      <Text className={styles.bottomText}>
        {translate(currentStateValues?.subTitle)}
        {redirectUrl && currentStateValues.linkText && (
          <>
            {' '}
            <Link href={redirectUrl}>
              {translate(currentStateValues.linkText)}
            </Link>
          </>
        )}
        <br />
        {currentStateValues?.additionalSubtitle &&
          translate(currentStateValues?.additionalSubtitle)}
      </Text>
    </div>
  )
}
